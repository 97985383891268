import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import api from '../../consts/api';
import { Trans, useTranslation } from 'react-i18next';

const Document = ({
  document,
  documentName,
  documentFlow,
  documentIndex,
  documentIndexCounter,
  totalDocuments,
  totalDocumentIndexCounter,
  handlePreviousDocument,
  candidateName,
  documents,
}) => {
  const [data, setData] = useState(undefined);
  const [isImageLoading, setIsImageLoading] = useState(false);
  documentIndexCounter = documentIndex + 1;
  totalDocumentIndexCounter = totalDocuments - 1;
  documentName = document.documentName;
  // document.visaType = 'sponsored';
  const shouldDisplayPreviousOption = () => {
    return documentIndex !== 0 || documentIndex > totalDocuments;
  };
  const { t } = useTranslation();
  useEffect(() => {
    setIsImageLoading(true);
    const fetchData = async () => {
      api
        .post(
          `user/document-image`,
          {
            imageRef: document.imageReference,
          },
          {
            responseType: 'arraybuffer',
          },
        )
        .then((response) => {
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          );
          setData('data:;base64,' + base64);
          setIsImageLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, [document.type, document.imageReference]);

  function renderDocumentName(documentName) {
    return documentName === undefined ? null : (
      <li key={documentName}>{documentName}</li>
    );
  }

  function renderDocumentVisaTypeRequirements(reasonBanner) {
    console.log(reasonBanner);

    return reasonBanner.map((reason) => (
      <>
        {t(reason)}
        <br />
      </>
    ));
  }

  return (
    <div>
      <div className="gov__document">
        {!documentFlow && (
          <div className="doc__into">
            <p> {t('document.instruction-one')} </p>
            {/* {totalDocuments === 2 && (
              <p>
                <Trans
                  i18nKey="document.single-document"
                  values={{ candidateName }}
                />
              </p>
            )} */}
            {/* {totalDocuments > 2 && (
              <p>
                <Trans
                  i18nKey="document.multi-document"
                  values={{ candidateName, totalDocumentIndexCounter }}
                />
              </p>
            )}
            <ul className="doc__point">
              {documents.map((document, index) => (
                <div key={index}>
                  {renderDocumentName(document.documentName)}
                </div>
              ))}
            </ul> */}
            {/* <p> {t('document.instruction-two')} </p> */}
          </div>
        )}

        {documentFlow && (
          <div>
            {shouldDisplayPreviousOption() && (
              <div className="positionBackBtn">
                <div className="gov__link" onClick={handlePreviousDocument}>
                  <Icon icon="gov__chevron left" label="Arrow left" />
                  <span> {t('document.previous')} </span>
                </div>
              </div>
            )}
            <p>
              <Trans
                i18nKey="document.step"
                values={{ documentIndexCounter, totalDocuments }}
              />
            </p>
            {document.type === 'live_photo' && (
              <p>{t('document.selfie-question')}</p>
            )}
            {document.type !== 'live_photo' && document.type !== 'share_code' && (
              <p>
                <Trans
                  i18nKey="document.document-question"
                  values={{ documentName }}
                />
              </p>
            )}

            {document.type === 'share_code' && (
              <p>{renderDocumentVisaTypeRequirements(document.reasonBanner)}</p>
            )}

            {isImageLoading ? (
              <h3>{t('document.image-loading')}</h3>
            ) : (
              <img
                src={data}
                width={'auto'}
                height={'auto'}
                alt="candidate"
                style={{ maxHeight: '600px', maxWidth: '500px' }}
              />
            )}

            {/* TODO: 2nd Image Logic */}
          </div>
        )}
      </div>
    </div>
  );
};

Document.propTypes = {
  document: PropTypes.object,
  documentFlow: PropTypes.bool,
  documentIndex: PropTypes.number,
  totalDocuments: PropTypes.number,
  handlePreviousDocument: PropTypes.func,
};

Document.defaultProps = {
  document: {},
  documentFlow: false,
  documentIndex: 1,
  totalDocuments: 1,
  handlePreviousDocument: null,
};

export default Document;
