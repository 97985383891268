import React, { useContext, useState, useEffect } from 'react';
import Icon from '../shared/Icon';
import PropTypes from 'prop-types';
import {SET_SEARCHED_CANDIDATES} from '../../state-management/actions/actionTypes';
import UserContext from '../../state-management/context/UserContext';
import {Trans, useTranslation} from 'react-i18next'

const SortDropDown = ({candidateList}) => {

  const [sortByDropdown, setSortByDropdown] = useState(null);
  const [userState, userDispatch] = useContext(UserContext);
  const {t} = useTranslation();

  useEffect(() => {
    setSortByDropdown(candidateList);
  }, [candidateList]);

  const ascendingSort = (sortByValue) => {
    return sortByDropdown.sort((a, b) => {
      if (a[sortByValue] < b[sortByValue]) return -1;
      if (a[sortByValue] > b[sortByValue]) return 1;
      return 0;
    });
  };
  
  const descendingSort = (sortByValue) => {
    return sortByDropdown.sort((a, b) => {
      if (a[sortByValue] < b[sortByValue]) return 1;
      if (a[sortByValue] > b[sortByValue]) return -1;
      return 0;
    });
  };

  const handleSelectBy = (e) => {
    const value = e.target.value.split('|');

    const sortedList =
      value[1] === "desc"
        ? descendingSort(value[0])
        : ascendingSort(value[0]);

    userDispatch({
      type: SET_SEARCHED_CANDIDATES,
      searchedCandidateList: sortedList
    });
  };  

  return (
    <div className="gov__form-group">
        <div className="gov__select-container">
           <div>
             <label htmlFor="sort-dropdown">{t('sort-drop-down.sort-by')}</label>
              <select id='sort-dropdown' defaultValue={'startDate|asc'} onChange={handleSelectBy} className="sort-by">
                <option value="startDate|asc" >{t('sort-drop-down.start-acc')}</option>
                <option value="startDate|desc">{t('sort-drop-down.start-dec')}</option>
                <option value="candidateName|asc">{t('sort-drop-down.name-acc')}</option>
                <option value="candidateName|desc">{t('sort-drop-down.name-dec')}</option>
              </select>
           </div>
           <div>
           <Icon icon="gov__chevron down" label="Arrow right"/>
           </div>
         </div>
    </div>
  );
};

SortDropDown.propTypes = {
  candidateList: PropTypes.array,
  setSortedCandidateList: PropTypes.func,
}

export default SortDropDown;
