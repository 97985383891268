import axios from 'axios';
import Cookies from "js-cookie";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
    withCredentials: true
});

api.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Token': Cookies.get('token'),
    "Access-Control-Allow-Credentials": "true",
    'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": '*',
};

export default api;
