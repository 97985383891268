import React, {useContext, useEffect, useState} from 'react';
import PropType from 'prop-types';
import Cookies from 'js-cookie';
import {Trans, useTranslation} from 'react-i18next'

// Context
import UserContext from '../../state-management/context/UserContext';
import {
  SET_INITIAL_CANDIDATES,
  SET_IS_LOADING,
  SET_MANAGER_AIN,
  SET_MANAGER_EMAIL,
  SET_SEARCHED_CANDIDATES,
  SET_SESSION_EXPIRED
} from '../../state-management/actions/actionTypes';

// Consts
import api from '../../consts/api';

const SessionContainer = ({children}) => {
  // Context variables
  const [userState, userDispatch] = useContext(UserContext);
  const {t} = useTranslation();

  // State variables
  const [error, setError] = useState('');

  useEffect(() => {
    initializeSession();

    // eslint-disable-next-line
  }, []);

  /**
   * Check
   */
  const initializeSession = async () => {
    console.log('Init session');
    userDispatch({type: SET_IS_LOADING, isLoading: true});

    const queryParams = new URLSearchParams(window.location.search);
    const ain = queryParams.get('ain');
    const email = queryParams.get('email');
    const token = queryParams.get('token');

    if (ain !== null && email !== null) {
      console.log('Demo session');
      initDemoSession(ain, email, token);
    }

    if (Cookies.get('token') !== null && Cookies.get('token') !== undefined) {
      console.log('Cookie found - check if session is available');

      const userToken = Cookies.get('token');
      console.log('userToken: ' + userToken);

      // Get details from middleware
      api.post('user/active-user').then((response) => {
        if (response.data) {
          console.log('Active user found');
          userDispatch({type: SET_MANAGER_EMAIL, managerEmail: response.data.email});
          userDispatch({type: SET_MANAGER_AIN, managerAin: response.data.ain});
          getInitialState(response.data.ain, userToken);
          userDispatch({type: SET_IS_LOADING, isLoading: false});
          userDispatch({type: SET_SESSION_EXPIRED, isSessionExpired: false});
        } else {
          console.log('Session not initialised or expired');
          userDispatch({type: SET_SESSION_EXPIRED, isSessionExpired: true});
        }
      }).catch(error => {
        console.log(error);
        userDispatch({type: SET_SESSION_EXPIRED, isSessionExpired: true});
      });
    } else {
      console.log('No cookie token found');
      userDispatch({type: SET_SESSION_EXPIRED, isSessionExpired: true});
    }
  };

  const getApiEndpoint = () => {
    api.get('/', ).then(response => {
      console.log(response.data);
    }).catch(error => {
      console.log(error);
    });
  }

  /**
   * Fetch the inital information required from Salesforce
   */
  const getInitialState = (ain, token) => {
    createIntervalCheck(token);
    api.post('user/candidates').then((response) => {
      userDispatch({
        type: SET_INITIAL_CANDIDATES,
        initialCandidateList: response.data
      });
      userDispatch({
        type: SET_SEARCHED_CANDIDATES,
        searchedCandidateList: response.data
      });
    }).catch(error => {
      console.log(error);
    });
  }

  const initDemoSession = (ain, email, token) => {
    Cookies.set('email', email);
    Cookies.set('ain', ain);
    Cookies.set('token', token);

    userDispatch({type: SET_IS_LOADING, isLoading: false});
    userDispatch({type: SET_MANAGER_EMAIL, managerEmail: email});
    userDispatch({type: SET_MANAGER_AIN, managerAin: ain});

    window.location.replace('/');
  }


  /**
   * Create a five second interval to check for the 'active' cookie
   */
  const createIntervalCheck = (token) => {
    // const isSessionActiveTimeout = setInterval(() => {
    //   api.post('user/active-user').then((response) => {
    //     console.log(response.data);
    //   }).catch(error => {
    //     console.log(error);
    //   });
    //   console.log('here');
    // }, 10000);
  };
console.log('process.env.REACT_APP_API_URL: ' + process.env.REACT_APP_API_URL);
console.log('process.env.REACT_APP_CLIENT_URL: ' + process.env.REACT_APP_CLIENT_URL);

  const serviceProviderRedirect = async () => {
    console.log('Redirecting to sso service provider route');
    window.location.replace('/api/saml')
  }
  
  //check to see if amazon-manager its in url
  if (userState.isSessionExpired) {
    return (
      <div className="gov__container">
        <div className="gov__session-expired">
          <div>
            <p>{t('session-expired')}</p>
            {window.location.href.includes(process.env.REACT_APP_CLIENT_URL) ? (
              <button onClick={serviceProviderRedirect} >Log In with SSO</button>
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="gov__container">
      <div className="gov__session-expired">{error}</div>
    </div>;
  }

  return children;
};

SessionContainer.propTypes = {
  children: PropType.array,
};

SessionContainer.defaultProps = {
  children: [],
};

export default SessionContainer;
