import React from "react";
import ReactDOM from "react-dom";
import {CSSTransition} from "react-transition-group";
import PropTypes from 'prop-types';


const CandidateInformation = props => {
  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{enter: 0, exit: 300}}>
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <a href="/#" className="close" onClick={props.onClose}> </a>
          <div className="modal-body">{props.children}</div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

CandidateInformation.propTypes = {
  handleAccept: PropTypes.func,
  handleReject: PropTypes.func,
};

export default CandidateInformation;
