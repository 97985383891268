import React from 'react';
import Icon from '../shared/Icon';
import PropTypes from 'prop-types';
import {Trans, useTranslation} from 'react-i18next'

const CandidateTile = ({candidate, getCandidateDetails}) => {
  const {t} = useTranslation();

  return (
    <div className="gov__candidate-combination"
         role="button"
         aria-pressed="false"
         onClick={() => getCandidateDetails(candidate.uuid)}>
      <p className="gov__candidate-combination-title">{t('candidate-tile.applicant-name')}</p>
      <div className="gov__candidate-combination-content">
        <div>
          <h3>
            {candidate.candidateName}
          </h3>

          <p>
          {t('candidate-tile.start-date')}<br/>
            {candidate.startDate}
          </p>
        </div>

        <div className="gov__candidate-combination-section">
          <Icon icon="gov__chevron right" label="Arrow right"/>
        </div>
      </div>
    </div>
  );
}

CandidateTile.propTypes = {
  candidate: PropTypes.object,
  getCandidateDetails: PropTypes.func,
}

CandidateTile.defaultProps = {
  candidate: [],
  getCandidateDetails: null,
}

export default CandidateTile;