import React, {useReducer} from 'react';
import '../assets/App.css';
import CandidateGrid from './candidate-grid/CandidateGrid'
import LoadingSpinner from "./loading-spinner/LoadingSpinner";
import Header from "./header/Header";
import UserContext from '../state-management/context/UserContext';
import UserReducer from '../state-management/reducers/UserReducer'
import SessionContainer from "./shared/SessionContainer";
import Sidebar from './shared/sidebar/Sidebar';

const App = () => {
  const initialUserState = {
    initialCandidateList: [],
    searchedCandidateList: [],
    currentCandidate: {},
    currentLanguage: sessionStorage.getItem('language') || 'en',
    isLoading: true,
    loadingMessage: '',
    user: null,
    isSessionExpired: false,
    managerEmail: '',
    sidebarStatus: '',
  };

  const userReducer = (state, action) => UserReducer(state, action);
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);

  return (
    <UserContext.Provider value={[userState, userDispatch]}>
      <SessionContainer>
        <Sidebar />
        <div className='wrapper'>
          <Header/>
          
          {userState.isLoading ?
            <LoadingSpinner/> :
            <CandidateGrid/>
          }
        </div>
      </SessionContainer>
    </UserContext.Provider>
  )
    ;
}

export default App;
