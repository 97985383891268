import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Trans, useTranslation } from 'react-i18next';
import { toTitleCase } from '../candidate-grid/CandidateGrid';
const DocumentPrivacy = ({
  allowExpired,
  document,
  documentName,
  documentFlow,
  documentIndex,
  documentIndexCounter,
  totalDocuments,
  totalDocumentIndexCounter,
  handlePreviousDocument,
  candidateName,
  documents,
  handleSubmit,
  handleReject
}) => {
  documentIndexCounter = documentIndex + 1;
  totalDocumentIndexCounter = totalDocuments - 1;
  documentName = document.documentName;
  const shouldDisplayPreviousOption = () => {
    return documentIndex !== 0 || documentIndex > totalDocuments;
  };
  const { t } = useTranslation();

  function renderDocumentName(document) {
    const docText = document.documentName ?? document.documentType
    /* return toTitleCase(docText); */
    return docText.replaceAll("_", " ").toUpperCase();
  }

  function renderDocumentVisaTypeRequirements(reasonBanner) {
    console.log(reasonBanner);

    return reasonBanner.map((reason) => (
      <>
        {t(reason)}
        <br />
      </>
    ));
  }

  const renderReasons = (reasonBanner) => {
    return reasonBanner.map((r, i) => <p key={`r-${i}`}>{r}</p>)
  }

  return (
    <div>
      <div className="gov__document">
        <p>{document.docName}</p>
        <p>
          <Trans
            i18nKey="document.step"
            values={{ documentIndexCounter, totalDocuments }}
          />
        </p>
        {!documentFlow && (
          <div className="doc__into">
            <div>
              <p>{t('candidate-grid.document-privacy.part-one')}</p>

              {!!document.reasonBanner.length && !allowExpired && <>{renderReasons(document.reasonBanner)}</>}

              <div className="modal-footer">
                <div className="button-center">
                  <div>
                    <button onClick={handleSubmit} className="button button-accept margin-left">
                      {t('candidate-grid.accept')}
                    </button>
                    <button onClick={handleReject} className="button button-reject">
                      {t('candidate-grid.reject')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DocumentPrivacy.propTypes = {
  allowExpired: PropTypes.bool,
  candidateName: PropTypes.string,
  document: PropTypes.object,
  documentFlow: PropTypes.bool,
  documentIndex: PropTypes.number,
  totalDocuments: PropTypes.number,
  handlePreviousDocument: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleReject: PropTypes.func,
};

DocumentPrivacy.defaultProps = {
  allowExpired: false,
  document: {},
  documentFlow: false,
  documentIndex: 1,
  totalDocuments: 1,
  handlePreviousDocument: null,
};

export default DocumentPrivacy;
