import {
  SET_INITIAL_CANDIDATES,
  SET_SEARCHED_CANDIDATES,
  SET_MANAGER_EMAIL,
  SET_MANAGER_AIN,
  SET_SESSION_EXPIRED,
  SET_IS_LOADING,
  SET_LOADING_MESSAGE,
  SET_LANGUAGE,
  SET_SIDEBAR,
} from '../actions/actionTypes';

const UserReducer = (state, action) => {
  switch (action.type) {
    case SET_INITIAL_CANDIDATES:
      return {
        ...state,
        initialCandidateList: action.initialCandidateList,
      };
    case SET_SEARCHED_CANDIDATES:
      return {
        ...state,
        searchedCandidateList: action.searchedCandidateList,
      };
    case SET_MANAGER_EMAIL:
      return {
        ...state,
        managerEmail: action.managerEmail,
      };
    case SET_MANAGER_AIN:
      return {
        ...state,
        managerAin: action.managerAin,
      };
    case SET_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.isSessionExpired,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.currentLanguage,
        sidebarStatus: action.sidebarStatus,
      };
    case SET_SIDEBAR:
      return {
        ...state,
        sidebarStatus: action.sidebarStatus,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_LOADING_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    default:
      break;
  }
};

export default UserReducer;
