import React, { useContext, useEffect, useState } from 'react';
import CandidateTile from '../candidate-tile/CandidateTile';
import api from '../../consts/api';
import CandidateInformation from '../candidate-information/CandidateInformation';
import Document from '../document/Document';
import DocumentPrivacy from '../document-privacy/DocumentPrivacy';
import DocumentRejection from '../document-rejection/DocumentRejection';
import UserContext from '../../state-management/context/UserContext';
import {
  SET_INITIAL_CANDIDATES,
  SET_IS_LOADING,
  SET_LOADING_MESSAGE,
  SET_SEARCHED_CANDIDATES
} from '../../state-management/actions/actionTypes';
import SortDropDown from '../sort-drop-down/SortDropDown';
import { Trans, useTranslation } from 'react-i18next';

export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const CandidateGrid = () => {
  const [userState, userDispatch] = useContext(UserContext);
  const [selectedCandidateDetails, setSelectedCandidateDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState({});
  const [documentIndex, setDocumentIndex] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [documentFlow, setDocumentFlow] = useState(false);
  const [documentRejectionFlow, setDocumentRejectionFlow] = useState(false);
  const [candidateName, setCandidateName] = useState('');
  const [documents, setDocuments] = useState(null);
  const [documentPrivacy, setDocumentPrivacy] = useState(false);
  const [consentProvided, setConsentProvided] = useState(true);
  const [currentUserUuid, setCurrentUserUuid] = useState('');
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [allowExpired, setAllowedExpired] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (userState.managerAin !== null && userState.managerAin !== '' && userState.managerAin !== undefined) {
      userDispatch({ type: SET_LOADING_MESSAGE, message: 'Approving candidate - Please Wait...' });
      api.post('user/candidates').then((response) => {
        userDispatch({
          type: SET_INITIAL_CANDIDATES,
          initialCandidateList: response.data
        });
        userDispatch({
          type: SET_SEARCHED_CANDIDATES,
          searchedCandidateList: response.data
        });
      }).catch(error => {
        console.log(error);
      });
    }
  }, [userDispatch, userState.managerAin]);

  const getCandidateDetails = (uuid) => {
    setCurrentUserUuid(uuid);
    api.post('user/candidate-details', { uuid: uuid }).then((response) => {
      if (response.data.documents.length > 0) {
        // Regular flow
        setDocuments(response.data.documents);
        setTotalDocuments(response.data.documents.length);
        setSelectedCandidateDetails(response.data);
        setCandidateName(response.data.candidateName);
        setCurrentDocument(response.data.documents[0]);

        // Document Privacy
        if (!response.data.documents[0].imageReference) {
          setDocumentPrivacy(true);
          setAllowedExpired(!!response.data.documents[0].allowExpired)
        }
        /*         if (!response.data.documents[0].allowExpired) {
                  setDocumentPrivacy(true);
                } */
        setShowModal(true);
      } else {
        // Consent not provided
        if (response.data.optedOut) {
          setConsentProvided(false);
          setShowModal(true);
        }
        // No documents found
      }

    }).catch((error) => {
      console.log(error);
    });
  };

  const getDocName = (document) => {
    const docName = document.documentName ?? document.documentType;
    return toTitleCase(docName);
  }

  const handleAccept = () => {
    if (!consentProvided || documentIndex + 1 === totalDocuments) {
      setSubmitConfirmation(true);
    } else {
      setDocumentIndex(documentIndex + 1);
      setCurrentDocument(selectedCandidateDetails.documents[documentIndex + 1]);
    }
  };

  const handleSubmit = () => {
    userDispatch({ type: SET_IS_LOADING, isLoading: true });
    api.post('user/accept', { uuid: currentUserUuid }).then(() => {
      closeModal();

      api.post('user/log', { message: `${userState.managerAin} accepted ${currentUserUuid}` }).catch(error => {
        console.log(error);
      });

      // Update candidate list (this will remove the candidate just accepted)
      api.post('user/candidates').then((response) => {
        userDispatch({ type: SET_IS_LOADING, isLoading: false });
        userDispatch({ type: SET_LOADING_MESSAGE, message: '' });

        userDispatch({
          type: SET_INITIAL_CANDIDATES,
          initialCandidateList: response.data
        });
        userDispatch({
          type: SET_SEARCHED_CANDIDATES,
          searchedCandidateList: response.data
        });
      }).catch(error => {
        userDispatch({ type: SET_IS_LOADING, isLoading: false });
        userDispatch({ type: SET_LOADING_MESSAGE, message: '' });
        console.log(error);
      });
    }).catch((error) => {
      userDispatch({ type: SET_IS_LOADING, isLoading: false });
      userDispatch({ type: SET_LOADING_MESSAGE, message: '' });
      console.log(error);
    });
  };

  const handleReject = () => {
    setDocumentRejectionFlow(true);
  };

  const handlePreviousDocument = () => {
    setDocumentIndex(documentIndex - 1);
    setCurrentDocument(selectedCandidateDetails.documents[documentIndex - 1]);
  };

  const handleReturnToDocument = () => {
    setDocumentRejectionFlow(false);
  };

  const resetSelectedCandidate = () => {
    setSelectedCandidateDetails([]);
    setDocumentFlow(false);
    setDocumentRejectionFlow(false);
    setDocumentIndex(0);
    setCandidateName('');
    setDocuments([]);
    setConsentProvided(true);
    setCurrentUserUuid('');
    setSubmitConfirmation(false);
  }

  const closeModal = () => {
    setShowModal(false);
    setDocumentPrivacy(false);
    setAllowedExpired(false);
    setTimeout(() => {
      resetSelectedCandidate();
    }, 1000);
  };

  return (

    <div className="marginsList">
      {userState.initialCandidateList.length === 0 &&
        <div>
          <h2>{t('candidate-grid.no-candidates')}</h2>
        </div>
      }

      {userState.initialCandidateList.length > 0 &&
        <SortDropDown
          candidateList={userState.searchedCandidateList}>
        </SortDropDown>
      }

      <div className="flexgrid">
        {
          userState.searchedCandidateList.map((candidate) => {
            return (
              <CandidateTile
                key={candidate.uuid}
                getCandidateDetails={getCandidateDetails}
                candidate={candidate} />
            );
          })
        }

        <CandidateInformation
          title={selectedCandidateDetails.candidateName}
          onClose={() => closeModal()}
          show={showModal}>

          <div className="modal-header">
            <p>{selectedCandidateDetails.candidateName}</p>
          </div>

          {!documentRejectionFlow && !documentPrivacy && consentProvided && !submitConfirmation &&
            <div>
              <div>
                {selectedCandidateDetails &&
                  <div>
                    <Document
                      document={currentDocument}
                      documentFlow={documentFlow}
                      documentIndex={documentIndex}
                      totalDocuments={totalDocuments}
                      handlePreviousDocument={handlePreviousDocument}
                      candidateName={candidateName}
                      documents={documents}
                    />
                  </div>
                }
              </div>
              <div className="modal-footer">
                <div className="button-center">
                  {!documentFlow &&
                    <button onClick={() => setDocumentFlow(true)} className="button button-accept">
                      {t('candidate-grid.start')}
                    </button>
                  }

                  {documentFlow &&
                    <div>
                      <button onClick={() => handleAccept()} className="button button-accept margin-left">
                        {t('candidate-grid.accept')}
                      </button>
                      <button onClick={() => handleReject()} className="button button-reject">
                        {t('candidate-grid.reject')}
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          }

          {/* CONFIRMATION MODAL */}
          {submitConfirmation && !documentRejectionFlow &&
            <div>
              <p>{t('candidate-grid.confirmation.part-one')}</p>
              <p>{t('candidate-grid.confirmation.part-two')}</p>

              <div className="modal-footer">
                <div className="button-center">
                  <div>
                    <button onClick={() => handleSubmit(selectedCandidateDetails.uuid)}
                      className="button button-accept margin-left">
                      {t('candidate-grid.confirmation.submit')}
                    </button>
                    <button onClick={() => setSubmitConfirmation(false)} className="button button-reject">
                      {t('candidate-grid.confirmation.return')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

          {/* REJECT CANDIDATE MODAL */}
          {documentRejectionFlow &&
            <div>
              <DocumentRejection
                uuid={currentUserUuid}
                handleReturnToDocument={handleReturnToDocument}
                closeModal={closeModal}>
              </DocumentRejection>
            </div>
          }

          {/* CONSENT NOT PROVIDED MODAL */}
          {!consentProvided && !documentRejectionFlow &&
            <div>
              <p>{t('candidate-grid.no-consent')}</p>
              <div className="modal-footer">
                <div className="button-center">
                  <div>
                    <button onClick={() => handleSubmit(currentUserUuid)} className="button button-accept margin-left">
                      {t('candidate-grid.accept')}
                    </button>
                    <button onClick={() => handleReject(currentUserUuid)} className="button button-reject">
                      {t('candidate-grid.reject')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

          {/* DOCUMENT PRIVACY MODAL */}
          {/* {documentPrivacy && !documentRejectionFlow && consentProvided && !submitConfirmation &&
            <div>

              <div>
                {!documentFlow && <><p>{t('candidate-grid.document-privacy.part-one')}</p>
                  <p><Trans i18nKey="candidate-grid.document-privacy.part-two" values={{ candidateName: selectedCandidateDetails.candidateName }} /><br />
                  </p>
                  <ul>
                    {documents.map(document => (<li key={document.documentName ?? document.documentType}>{getDocName(document)}</li>))}
                  </ul></>}
                {selectedCandidateDetails &&
                  <div>
                    <DocumentPrivacy
                      document={currentDocument}
                      documentFlow={documentFlow}
                      documentIndex={documentIndex}
                      totalDocuments={totalDocuments}
                      allowExpired={allowExpired}
                      handlePreviousDocument={handlePreviousDocument}
                      candidateName={candidateName}
                      documents={documents}
                    />
                  </div>
                }
              </div>
              <div className="modal-footer">
                <div className="button-center">
      

                  {
                    <div>
                      <button onClick={() => handleAccept()} className="button button-accept margin-left">
                        {t('candidate-grid.accept')}
                      </button>
                      <button onClick={() => handleReject()} className="button button-reject">
                        {t('candidate-grid.reject')}
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          } */}

          {documentPrivacy && !documentRejectionFlow &&
            <DocumentPrivacy
              document={currentDocument}
              documentFlow={documentFlow}
              documentIndex={documentIndex}
              totalDocuments={totalDocuments}
              allowExpired={allowExpired}
              handlePreviousDocument={handlePreviousDocument}
              candidateName={candidateName}
              documents={documents}
              handleSubmit={() => handleSubmit(selectedCandidateDetails.uuid)}
              handleReject={() => handleReject(selectedCandidateDetails.uuid)}
            />
            /*   <div>
                <p>{t('candidate-grid.document-privacy.part-one')}</p>
                <p><Trans i18nKey="candidate-grid.document-privacy.part-two" values={{ candidateName: selectedCandidateDetails.candidateName }} /><br />
                </p>
                <ul>
                  {documents.map(document => (<li key={document.documentName ?? document.documentType}>{document.documentName ?? document.documentType}</li>))}
                </ul>
                <p>{t('candidate-grid.document-privacy.part-three')}</p>
  
                <div className="modal-footer">
                  <div className="button-center">
                    <div>
                      <button onClick={() => handleSubmit(selectedCandidateDetails.uuid)} className="button button-accept margin-left">
                        {t('candidate-grid.accept')}
                      </button>
                      <button onClick={() => handleReject(selectedCandidateDetails.uuid)} className="button button-reject">
                        {t('candidate-grid.reject')}
                      </button>
                    </div>
                  </div>
                </div>
              </div> */
          }

        </CandidateInformation>
      </div>
    </div>
  );
};

export default CandidateGrid;
