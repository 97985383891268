import React, {useContext} from 'react';
import UserContext from "../../state-management/context/UserContext";
import {SET_SEARCHED_CANDIDATES} from "../../state-management/actions/actionTypes";
import { useTranslation } from 'react-i18next';

const Searchbar = () => {
  const [userState, userDispatch] = useContext(UserContext);

  const { t } = useTranslation();

  const handleSearchInput = (text) => {
    let refinedList = [];

    userState.initialCandidateList.forEach((candidate) => {
      if (candidate.candidateName.toLowerCase().includes(text.toLowerCase())) {
        console.log(candidate.candidateName);
        refinedList.push(candidate);
      }
    })

    userDispatch({
      type: SET_SEARCHED_CANDIDATES,
      searchedCandidateList: refinedList
    });
  };

  return (
    <div>
      <input
        className="gov__candidate-search"
        aria-label="Search Candidate"
        placeholder={t('landing-page.search-candidate')}
        onChange={(event) => handleSearchInput(event.target.value)}
        type="text"/>
    </div>
  )
}

export default Searchbar;