import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import api from "../../consts/api";
import UserContext from "../../state-management/context/UserContext";
import {Trans, useTranslation} from 'react-i18next'

const DocumentRejection = ({uuid, handleReturnToDocument, closeModal}) => {
  const [userState, userDispatch] = useContext(UserContext);
  const [documentIssue, setDocumentIssue] = useState(false);
  const [candidateIssue, setCandidateIssue] = useState(false);
  const [candidatePhotoUnclear, setCandidatePhotoUnclear] = useState(false);
  const [documentPhotoIssue, setDocumentPhotoIssue] = useState(false);
  const [other, setOther] = useState(false);
  const [descriptionText, setDescriptionText] = useState('');
  const {t} = useTranslation();

  const submitRejectionReason = (uuid) => {
    const rejectionObject = {
      uuid: uuid,
      rejectionReasons: {
        documentIssue: documentIssue,
        candidateIssue: candidateIssue,
        candidatePhotoUnclear: candidatePhotoUnclear,
        documentPhotoIssue: documentPhotoIssue,
        other: other
      },
      description: descriptionText
    }
    // TODO; Change to manager email from user state
    api.post('user/reject', {uuid: uuid, rejectionReasons: rejectionObject, reason: rejectionObject.description}).then((response) => {
      if (response) {
        closeModal();
      } else {
        console.log('error with POST to user/reject');
      }
    });
  };

  return (
    <div>
      <div className="positionBackBtn">
        <div className='gov__link' onClick={handleReturnToDocument}>
          <Icon icon="gov__chevron left" label="Arrow left"/>
          <span>{t('document-rejection.return')}</span>
        </div>
      </div>
      <div className="gov__incorrect-information-container">
        <p className="gov__incorrect-information-subtitle">{t('document-rejection.instruction-one')}</p>

        <div className='gov__incorrect-information-options'>
          <p className="gov__incorrect-information-select-option">{t('document-rejection.select')}</p>

          <div className='gov__gov__incorrect-information-option'>

            <div className="gov__checkbox-container">
              <label className="gov__checkbox-label">
                <input type="checkbox"/>
                <span className="gov__checkmark" role="button" aria-pressed="true"
                      aria-label="Checkmark icon"
                      onClick={() => setDocumentIssue(!documentIssue)}/>
              </label>
            </div>

            <div className="gov__option-text">
              {t('document-rejection.document-reject')}
            </div>
          </div>

          <div className='gov__gov__incorrect-information-option'>
            <div className="gov__checkbox-container">
              <label className="gov__checkbox-label">
                <input type="checkbox"/>
                <span className="gov__checkmark" role="button" aria-pressed="false"
                      aria-label="Checkmark icon"
                      onClick={() => setCandidateIssue(!candidateIssue)}/>
              </label>
            </div>
            <div className="gov__option-text">
              {/* {t('landing-page.incorrect-data.options.name')} */}
              {t('document-rejection.candidate-reject')}
            </div>
          </div>

          <div className='gov__gov__incorrect-information-option'>
            <div className="gov__checkbox-container">
              <label className="gov__checkbox-label">
                <input type="checkbox"/>
                <span className="gov__checkmark" role="button" aria-pressed="false"
                      aria-label="Checkmark icon"
                      onClick={() => setCandidatePhotoUnclear(!candidatePhotoUnclear)}/>
              </label>
            </div>
            <div className="gov__option-text">
            {t('document-rejection.candidate-photo-reject')}
            </div>
          </div>

          <div className='gov__gov__incorrect-information-option'>
            <div className="gov__checkbox-container">
              <label className="gov__checkbox-label">
                <input type="checkbox"/>
                <span className="gov__checkmark" role="button" aria-pressed="false"
                      aria-label="Checkmark icon"
                      onClick={() => setDocumentPhotoIssue(!documentPhotoIssue)}/>
              </label>
            </div>
            <div className="gov__option-text">
            {t('document-rejection.document-photo-reject')}
            </div>
          </div>

          <div className='gov__gov__incorrect-information-option'>
            <div className="gov__checkbox-container">
              <label className="gov__checkbox-label">
                <input type="checkbox"/>
                <span className="gov__checkmark" role="button" aria-pressed="false"
                      aria-label="Checkmark icon"
                      onClick={() => setOther(!other)}/>
              </label>
            </div>
            <div className="gov__option-text">
              {t('document-rejection.other-reject')}
            </div>
          </div>
        </div>
      </div>
      <div className="gov__contact-form">
        <p className="gov__contact-form-subtext">{t('document-rejection.more-info')}</p>

        <textarea
          className="gov__textarea"
          maxLength="500"
          onChange={(event) => setDescriptionText(event.target.value)}
          placeholder={t('document-rejection.placeholder')}
          value={descriptionText}
        />
        {(descriptionText.length <= 499) &&
        <p
          className={`gov__text xxs ${descriptionText.length > 499 ? 'red' : ' light grey'} gov__character-count`}>{descriptionText.length + " characters"}</p>
        }

        {(descriptionText.length > 499) &&
        <p
          className={`gov__text xxs ${descriptionText.length > 499 ? 'red' : ' light grey'} gov__character-count`}>{'Max 500 characters'}</p>
        }

      </div>
      <div className="modal-footer button-center">
        <button
          disabled={(!documentIssue && !candidateIssue && !candidatePhotoUnclear && !documentPhotoIssue && !other)}
          onClick={() => submitRejectionReason(uuid)}
          className="button button-reject">
          {t('document-rejection.submit')}
        </button>
      </div>
    </div>

  );

}

DocumentRejection.propTypes = {
  submitRejectionReason: PropTypes.func,
  uuid: PropTypes.string,
  handleReturnToDocument: PropTypes.func
}

export default DocumentRejection;
