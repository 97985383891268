import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check'

// Translation files
import englishTranslations from './en.json';
import deutscheTranslations from './de.json';
import dutchTransaltions from './nl.json';
import polishTranslations from './pl.json';

const translations = {
    en: {
        translation: englishTranslations,
    },
    de: {
        translation: deutscheTranslations,
    },
    nl: {
        translation: dutchTransaltions,
    },
    pl: {
        translation: polishTranslations,
    }
};

i18n.use(i18nextPlugin).init({
    resources: translations,
    lng: sessionStorage.getItem('language') || 'en',
    interpolation: {
        escapeValue: false,
    },
});

i18n.use(initReactI18next).init({
    resources: translations,
    lng: sessionStorage.getItem('language') || 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
