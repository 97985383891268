import React, {useContext} from 'react';
import UserContext from "../../state-management/context/UserContext";

const UserInfo = () => {
  const [userState, userDispatch] = useContext(UserContext);

    return(
        <div className="infobar">
            {/*<button className="button button1" type="button">{userState.managerAin}</button>*/}
            {/*<button className="button button1" type="button" onClick={() => handleLogout()} href="/#"> Logout</button>*/}
        </div>
    )
}
let handleLogout = () => {
    alert("Logout Succesfully");
};
export default UserInfo;
