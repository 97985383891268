import React, {useContext} from 'react';
import UserInfo from '../user-info/UserInfo';
import Searchbar from '../searchbar/Searchbar';
import Logo from '../../assets/TJX_Logo.svg.png';
import UserContext from '../../state-management/context/UserContext';

import { SET_SIDEBAR } from '../../state-management/actions/actionTypes';
import Icon from '../shared/Icon';

import { useTranslation } from 'react-i18next';

const Header = () => {
  const [userState, userDispatch] = useContext(UserContext);
  const { t } = useTranslation();
  console.log(userState.currentLanguage)
  return (
    <div className="header">
      <div className="left-col">
        <div className="gov__logo">
          <div>
            <span className="gov__deloitte">Go</span>Verify
            <span>
              <strong>.</strong>
            </span>
          </div>
        </div>
        {/*<img className="logo" src={Logo} height="auto" width="auto" alt=""/>*/}
      </div>

      <div className="center-col">
        <Searchbar />
      </div>
      <div className="right-col">
        <UserInfo />
      </div>
      <div
        className="gov__language-nav"
        role="button"
        aria-pressed="false"
        onClick={() =>
          userDispatch({ type: SET_SIDEBAR, sidebarStatus: 'visible' })
        }
      >
        <Icon
          icon={`gov__language-icon ${userState.currentLanguage}`}
          label="Language icon"
        />
        <span>{userState.currentLanguage.toUpperCase()}</span>
      </div>
    </div>
  );
};

export default Header;
